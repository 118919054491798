import React from 'react';
import mixpanel from 'mixpanel-browser';
import PaymentPayPal from "./PaymentPayPal";
import Checkbox from "./Checkbox";
import Paysafe from "./Paysafe";
import PaymentStripeWallet from "./PaymentStripeWallet";
import PaymentStripeCard from "./PaymentStripeCard";
import {useTranslation} from "react-i18next";

export default function Payment({data, nextUrl, prefix = ''}) {
  const {t, i18n} = useTranslation('common', {keyPrefix: 'payment'});

  const [productData, setProductData] = React.useState();
  // const [why, setWhy] = React.useState(false);

  React.useEffect(() => {
    if (!data)
      return;

    setProductData({
      ...data,
      uid: window.localStorage.getItem('uid'),
      email: (window.localStorage.getItem('email') || '')
    });

    const productName = data.name.replace(' ', '') + '_' + data.fullPrice;
    mixpanel.track_pageview({page: 'payment', product: productName});
    window.localStorage.setItem('product', productName);
  }, [data]);

  React.useEffect(() => {
    for (const item of paymentList) {
      if (item.applePay)
        item.name = t('applePay');
      else if (item.googlePay)
        item.name = t('googlePay');
      else if (item.paypal)
        item.name = t('payPal');
      else if (item.credit)
        item.name = t('credit');
    }

    setPaymentList([...paymentList]);
  }, [i18n.resolvedLanguage]);

  // React.useEffect(() => {
  //   const remove = () => {
  //     document.body.classList.remove('pop');
  //     document.querySelectorAll('.shadow').forEach(e => e.remove());
  //   };
  //
  //   if (why) {
  //     document.body.classList.add('pop');
  //
  //     const div = document.createElement('div');
  //     div.classList.add('shadow');
  //     document.body.append(div);
  //   } else
  //     remove();
  //
  //   return remove;
  // }, [why]);

  const check = <img src={require('../../src/assets/img/icon/check.svg').default} width={12} height={8} alt={''}/>;

  const [paymentList, setPaymentList] = React.useState([
    {
      name: '...',
      pay: true,
      component: PaymentStripeWallet,
    },
    {
      name: t('payPal'),
      paypal: true,
      img: <img src={require('../assets/img/paywall/paypal-pay.png')} width={52} height={20} alt={t('payPal')} />,
      component: PaymentPayPal,
    },
    {
      name: t('credit'),
      credit: true,
      img: <img src={require('../assets/img/paywall/card-pay.png')} width={207} height={20} alt={t('credit')} />,
      component: PaymentStripeCard,
    },
  ]);

  const [paymentIndex, setPaymentIndex] = React.useState(0);

  function handleStripeResult(result) {
    // console.log('handleStripeResult', result)

    if (paymentList[0].pay) {
      if (result?.applePay) {
        paymentList[0] = {
          ...paymentList[0],
          applePay: true,
          name: t('applePay'),
          img: <img src={require('../assets/img/paywall/apple-pay.png')} width={35} height={20} alt={t('applePay')}/>,
        };
      } else if (result?.googlePay) {
        paymentList[0] = {
          ...paymentList[0],
          googlePay: true,
          name: t('googlePay'),
          img: <img src={require('../assets/img/paywall/google-pay.png')} width={35} height={20} alt={t('googlePay')}/>,
        };
      } else
        paymentList.shift();

      setPaymentList([...paymentList]);
    }
  }

  const getPeriod = (field, first) => {
    const periods = t('periods', {returnObjects: true});

    return periods[data?.period]?.[first] || periods[data?.period]?.[field] || data?.period;
  };

  return (
    <div id={'payment'} className={'block items text-start'}>
      <div className={'row wide'}>
        <strong>{t('title')}</strong>
        {/*<em role={'button'} className={'note md'} onClick={() => setWhy(true)}>Why now?</em>*/}
      </div>

      {
        // why &&
        // <div className={'window'}>
        //   <div className={'items'}>
        //     <p className={'notice'}>We ask for your payment information now so you can enjoy Simple uninterrupted after your 30-day trial ends.</p>
        //     <p className={'notice'}>If you cancel anytime before the end of the 30-day trial, you won't be charged.</p>
        //     <Button onClick={() => setWhy(false)}>Got it</Button>
        //   </div>
        // </div>
      }

      <hr/>

      <p className={'notice'}>
        {t(prefix + 'notice', {
          currencySymbol: data?.currencySymbol,
          fullPrice: data?.fullPrice,
          period: getPeriod('text')
        })}
      </p>

      {/*<PaymentStripeCheckWallets data={productData}/>*/}

      {
        paymentList.map((item, index) => {
          const checked = paymentIndex === index;

          return (
            <div key={index} className={'block-plan items' + (checked ? ' active' : '')} onClick={() => setPaymentIndex(index)}>
              <div className={'item'}>
                {
                  paymentList.length > 1 &&
                  <Checkbox type={'radio'} name={'plan' + (index ? '-' + index : '')} checked={checked} readOnly={true}/>
                }
                <div className={'w-100 text-start'}>
                  <strong>{item.name}</strong>
                  {
                    item.credit &&
                    <p className={'mt-1'}>{item.img}</p>
                  }
                </div>
                {!item.credit && item.img}
              </div>
              {
                checked && item.pay &&
                <div className={'items md small'}>
                  <div className={'row sm'}>{check}<em>{item.applePay ? t('easyApple') : t('easy')}</em></div>
                  <div className={'row sm'}>{check}<em>{t('encryption')}</em></div>
                  <div className={'row sm'}>{check}<em>{t('accountNumber', {name: item.name})}</em></div>
                </div>
              }
              {
                checked && item.paypal &&
                <div className={'items md small'}>
                  <div className={'row sm'}>{check}<em>{t('fast')}</em></div>
                  <div className={'row sm'}>{check}<em>{t('encryption')}</em></div>
                  <div className={'row sm'}>{check}<em>{t('protection')}</em></div>
                </div>
              }
              {
                checked && item.component && productData &&
                React.createElement(item.component, {data: productData, onResult: handleStripeResult, nextUrl})
              }
              {
                checked && (item.paypal || item.credit) &&
                <Paysafe payment={true}/>
              }
            </div>
          )
        })
      }

      {
        data?.price &&
        <>
          <p className={'notice sm'}>
            {t(prefix + 'subscription', {
              period: getPeriod('value', 'value-1'),
              dialect: getPeriod('dialect'),
              currency: data?.currency,
              price: data?.price
            })}
          </p>
          <div className={'row sm'}>
            {check}<em className={'small'}>{t(prefix + 'specialPrice', {
              period: getPeriod('value'),
              currencySymbol: data?.currencySymbol,
              fullPrice: data?.fullPrice
            })}</em>
          </div>
        </>
      }
    </div>
  )
}
