import React from 'react';
import Top from "./parts/Top";
import Plan from "./parts/Plan";

import mixpanel from 'mixpanel-browser';
import {useNavigate, useParams} from "react-router-dom";
import InfoPaywall from "./parts/InfoPaywall";
import Payment from "./parts/Payment";
import {Trans, useTranslation} from "react-i18next";

function Timer({time}) {
  return (
    <strong>{Math.floor(time / 60)}:{time % 60 < 10 ? 0 : ''}{time % 60}</strong>
  );
}

export default function Discount() {
  const {t} = useTranslation('common', {keyPrefix: 'paywall'});

  const {uid} = useParams();

  const [active, setActive] = React.useState();

  const [time, setTime] = React.useState(10 * 60);
  const navigate = useNavigate();

  // React.useLayoutEffect(() => {
  // }, []);

  React.useEffect(() => {
    window.localStorage.setItem('uid', uid);
    mixpanel.track_pageview({page: 'discount'});

    window.timer = setInterval(() => {
      setTime(time => {
        if (time <= 0) {
          mixpanel.track('discount is out');
          clearInterval(window.timer);
          navigate('/paywall', {replace: true});
        }

        return time - 1
      });
    }, 1000);

    return () => clearInterval(window.timer);
  }, []);

  return (
    <div className={'wrap'}>
      <Top className={'gap-3'}>
        <div className={'block items'}>
          <div style={{marginBottom: '-1.25rem', position: 'relative'}}>
            {
              time > 0 &&
              <div className={'block text-center'} style={{position: 'absolute', right: 0, top: 0, width: 'auto', padding: '0.5rem 1rem'}}>
                <Trans i18nKey="expiresDiscount" t={t}>
                  <p className={'notice sm'}>Expires in:</p>
                  <Timer time={time}/>
                </Trans>
              </div>
            }
            <img src={require('./assets/img/paywall/discount.png')} alt="" width={390} height={298} className={'mw-100'}/>
          </div>
          <Plan path={'discount'} discount={true} onChange={item => setActive(item)} />
        </div>

        <InfoPaywall/>
        <Payment data={active}/>
      </Top>
    </div>
  )
}
