import React from 'react';
import Faq from "./Faq";
import Comments from "./Comments";
import Winners from "./Winners";
import Stars from "./Stars";
import RiveLoad from "./RiveLoad";
import {Trans, useTranslation} from "react-i18next";
import {LanguageEnum} from "../_business/LanguageEnum";
import {getItem, getSurveyData} from "../utils/lib";

function GoalBlock() {
  const {t, i18n} = useTranslation();

  // const A16 = getExperiment('A16')
  //
  // if (A16.value === 'A')
  //   return;

  const
    surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage),
    resultList = getItem('survey_results'),
    s1 = surveyList.findIndex(({id}) => id === 'target1'),
    g1 =
      window.landing.includes('masculinity')
        ? surveyList[s1].list.findIndex(({id, title}) => id === 'Masculinity' || title === 'Masculinity')
        : +resultList?.[s1]?.[0] || 0;

  let goalId, goalTitle;

  try {
    goalTitle = surveyList[s1].list[g1]?.title;
    goalId = surveyList[s1].list[g1]?.id || goalTitle;
    goalId = goalId.toLowerCase().replace(/ /g, '-');
  } catch (e) {
    console.error(e);
  }

  // console.log(goalId)

  return (
    <img className={'w-100 mw-100'} width={350} height={200} alt={goalTitle}
         src={require(`../assets/i18/${i18n.resolvedLanguage}/img/phone/${goalId}.png`)}
    />
  )
}

export default function InfoPaywall({advantagesList}) {
  const {t, i18n} = useTranslation('common', {keyPrefix: 'paywall.info'});

  // const A35 = getExperiment('A35')

  return (
    <>
      <div className={'block'}>
        <div style={{width: 80, height: 80, float: 'right', marginLeft: '1.25rem', marginBottom: '0.5rem'}}>
          <RiveLoad width={80} height={80} locale={['de', 'fr'].includes(i18n.resolvedLanguage)}
                    src={`moneyback.riv`} stateMachines="money_back"
                    inputName={'localisation_id'}
                    value={['en', 'es'].includes(i18n.resolvedLanguage) ? LanguageEnum[i18n.resolvedLanguage] : LanguageEnum.en}
          />
        </div>
        <p className={'mb-2'}><strong>{t('moneyBack.title')}</strong></p>
        <p className={'notice sm mb-2'}>{t('moneyBack.p1')}</p>
        <p className={'notice sm'}>
          <Trans i18nKey="moneyBack.p2" t={t}>
            We guarantee you’ll see the results or you’ll receive a full refund within 30 days after your purchase. Find out more about the refund policy in our <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'subscription'} className={'link nowrap'} target={'_blank'}>Subscription Policy</a>.
          </Trans>
        </p>
      </div>

      <div className={'block items'}>
        <h3 className={'h2 text-center'} dangerouslySetInnerHTML={{__html: t('advantages.title')}}/>
        <div className={'paragraph'}>
          {
            (advantagesList || t('advantages.list', {returnObjects: true})).map((item, i) =>
              <p key={i} className={'notice small row'} style={{gap: '0.75rem'}}>
                <img src={require('../assets/img/icon/star2.svg').default} alt={'★'}/>
                {item}
              </p>
            )
          }
        </div>
      </div>

      <GoalBlock/>

      <Faq/>

      {
        // A35.value === 'B' &&
        // <div className={'block items gap-3'}>
        //   <img src={require('../assets/img/paywall/support.png')} alt={''} width={180} height={84}
        //        className={'d-block mx-auto mb-1'}/>
        //
        //   <div className={'tag tag-btn-sm h4 row w-auto gap-2'}
        //        style={{backgroundColor: '#9BE760', color: 'var(--bg-100)', fontWeight: 'bold', lineHeight: 1.25}}
        //   >
        //     <img src={require('../assets/img/icon/support.svg').default} alt={''} width={15} height={14}/>
        //     24/7
        //   </div>
        //
        //   <h3>{t('support.title')}</h3>
        //   <p className={'notice sm text-center'}>
        //     {t('support.desc')}
        //   </p>
        // </div>
      }

      <div className={'block items'}>
        <h3 className={'h2'}>{t('love')}</h3>
        <p className={'notice small text-center'}>
          <Trans i18nKey={'countries'} t={t}>
            Become a member of a global voice improvement community with users from <span className={'marked'}>80+ countries</span>
          </Trans>
        </p>
        <Comments />
        <Winners/>
      </div>

      <div className={'block items'}>
        <div className={'row text-center'} style={{justifyContent: 'space-around', alignItems: 'flex-end'}}>
          <div>
            <Stars className={'mb-1'}/>
            <Trans i18nKey={'rating'} t={t}>
              <em className={'h3 marked'}>4,6</em> <small className={'notice'}>out of 5</small>
            </Trans>
          </div>
          <a rel="noreferrer" href={process.env.REACT_APP_IOS_LINK} target={'_blank'}>
            <img src={require('../assets/img/paywall/app-store-logo.svg').default} alt={''} width={28} height={28} className={'d-block mx-auto mb-1'}/>
            <small className={'notice'}>{t('appStore')}</small>
          </a>
          <a rel="noreferrer" href={process.env.REACT_APP_GOOGLE_LINK} target={'_blank'}>
            <img src={require('../assets/img/paywall/google-play-logo.svg').default} alt={''} width={28} height={28} className={'d-block mx-auto mb-1'}/>
            <small className={'notice'}>{t('googlePlay')}</small>
          </a>
        </div>
        <div className={'row gap-2 small-sm'}>
          <div className={'panel row gap-2 center'}>
            <Trans i18nKey={'ratings'} t={t}>
              <strong>25K</strong><em className={'notice'}>5-star ratings</em>
            </Trans>
          </div>
          <div className={'panel row gap-2 center'}>
            <Trans i18nKey={'downloads'} t={t}>
              <strong>2M</strong><em className={'notice'}>downloads</em>
            </Trans>
          </div>
        </div>
      </div>
    </>
  )
}
